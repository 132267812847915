import { PopupContentProps, ShorthandValue } from "@fluentui/react-northstar";
import { User } from "../types/types";
import { ICompanyInfoObject } from "../actions/backendCalls";

export interface IReportsTab {
    theme: string;
    context: any;
    jwtoken: string;
    decodedToken: any;
    isMobile: boolean;
    data: any[];
    projectsList: any[];
    userList: any[];
    projectsData: any[];
    usersData: any[];
    companiesList: any[];
    companiesData: any[];
    projectCompanyID: ICompanyInfoObject[];
    isDisabled: boolean;
    isCompanyDisabled: boolean;
    startDate: number | null;
    endDate: number | null;
    selectedProjects: any[];
    selectedProjectsName: any[];
    selectedUsersNames: any[];
    selectedUsersIDs: any[];
    selectedCompany: string | undefined;
    selectedCompanyName: string | undefined;
    companySelected: boolean;
    debug: any;
    popup: ShorthandValue<PopupContentProps>;
    serverSideToken: string | null;
    selectedDateStart: Date | undefined;
    selectedDateEnd: Date | undefined;
    getAllChecked: boolean;
    reportType: string | undefined;
    ready: boolean;
    datePickerKey: string;
    projectTypeSelected: boolean;
    year: number;
    userSelected: boolean;
    user: User;
    errorMsg: string;
    error: boolean;
}

export enum ReportType {
    TIME_ENTRIES = "Time entries",
    YEAR_REPORT = "Year report",
}

export const REPORT_TYPES: string[] = ["Time entries", "Year report"];
