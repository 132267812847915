import { IProject, ITimeEntry, IUser, User } from "../types/types";

const baseUrl = process.env.REACT_APP_APIURL;
export const authUrl = `${baseUrl}/auth`;
export const projectUrl = `${baseUrl}/project`;
export const userUrl = `${baseUrl}/user`;
export const companyUrl = `${baseUrl}/company`;
export const timeEntryUrl = `${baseUrl}/timeEntry`;

export interface ICompany {
    id: string; // Database id
    company_name: string; // Company name
    locked: boolean; // Is the company locked?
}

export interface IProjectCompnayID {
    project_name: string;
    company_id: string;
    project_id: string;
}

export interface IHasHoursResponse {
    companies: string[];
    projects: string[];
    projectCompanyID: IProjectCompnayID[];
}

export interface ICompanyInfoObject {
    company_name: string;
    id: string;
    locked: boolean;
}

// import { baseUrl } from "../utils/constants";
const getHeader = async (token: string) => {
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "x-frontend-version": `${process.env.REACT_APP_VERSION}`,
        // "Access-Control-Allow-Origin": `${baseUrl}`,
    };
};

/**
 * Handle errors from backend
 * @param response Response from backend
 * @returns Promise with response
 */
function handleErrors(response: Response): any {
    return new Promise<void>((resolve, reject) => {
        if (!response.ok) {
            if (response.status === 409) {
                console.error(`Frontend and backend version mismatch!`);
            }
            return reject(response);
        }

        if (response.status === 204) {
            return resolve();
        }

        return response.json().then(resolve).catch(resolve);
    });
}

/** Fetch serverside token (AAD) */
export const getServerSideToken = async (token: string, tid: string) => {
    return fetch(`${authUrl}/aadToken`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify({
            tid: tid,
            token: token,
        }),
        mode: "cors",
        cache: "default",
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Get server side version */
export const getServerSideVersion = async () => {
    return fetch(`${baseUrl}/versionNumber`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        mode: "cors",
        cache: "default",
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

export interface IFetchHoursBody {
    company?: string;
    projects?: any[];
    from?: number;
    to?: number;
    getAll?: boolean;
    users?: any[];
}

/** Fetch hours from DB and save file into oneDrive */
export const fetchHours = async (token: string, body: IFetchHoursBody) => {
    return fetch(`${projectUrl}/reports/hours`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
        mode: "cors",
        cache: "default",
    }).then(async (response) => {
        console.log("response HERE", response);
        if (!response.ok) {
            throw await response.json();
        }

        return response.arrayBuffer();
    });
};

/** Fetch yearly total for all Offcode users & companies from DB and save file into oneDrive */
export const fetchYearlyTotalOffcode = async (token: string, body: IFetchHoursBody): Promise<any> => {
    return fetch(`${projectUrl}/reports/yearlyTotalOffcode`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
        mode: "cors",
        cache: "default",
    }).then(async (response) => {
        console.log("response HERE", response);
        if (!response.ok) {
            throw await response.json();
        }

        return response.arrayBuffer();
    });
};

/** Fetch yearly total from DB and save file into oneDrive */
export const fetchYearlyTotal = async (token: string, body: IFetchHoursBody): Promise<any> => {
    return fetch(`${projectUrl}/reports/yearlyTotal`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
        mode: "cors",
        cache: "default",
    }).then(async (response) => {
        console.log("response HERE", response);
        if (!response.ok) {
            throw await response.json();
        }

        return response.arrayBuffer();
    });
};

/** Fetch companies / projects that has time entries for current user  */
export const fetchHasHours = async (token: string): Promise<IHasHoursResponse> => {
    return fetch(`${projectUrl}/hasHours`, {
        method: "GET",
        headers: await getHeader(token),
        // body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch projects based on company id */
export const getProjects = async (token: string, id: any): Promise<IProject[]> => {
    return fetch(`${projectUrl}/projects/${id}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch all companies */
export const getCompanies = async (token: string): Promise<ICompany[]> => {
    return fetch(`${companyUrl}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Create company */
export const createCompany = async (token: string, body: { company_name: any; locked: boolean }) => {
    return fetch(`${companyUrl}`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch company settings based on company name */
export const getCompanyByName = async (token: string, name: string): Promise<ICompanyInfoObject> => {
    return fetch(`${companyUrl}/name/${name}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch Users */
export const getUsers = async (token: string): Promise<IUser[]> => {
    return fetch(`${userUrl}/?all`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update User app_settings */
export const updateUserAppSettings = async (token: string, body: { app_settings: any }): Promise<User> => {
    // console.log(body);
    return fetch(`${userUrl}/app_settings`, {
        method: "PUT",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Set new user */
export const setNewUser = async (token: any, body: any) => {
    return fetch(`${userUrl}`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Fetch User */
export const getUser = async (token: string): Promise<User> => {
    return fetch(`${userUrl}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
    // .then((response) => response.json())
    // .then((user: User) => {
    //     return user;
    // })
    // .catch((err) => {
    //    throw err;
    // });
};

// /** Get weektotal */
// export const getWeekTotal = async (token, from, to) => {
//     return fetch(
//         `${timeEntryUrl}/weekTotal/${from}/${to}`,
//         {
//             method: "GET",
//             headers: await getHeader(token),
//         }
//     )
//         .then(handleErrors)
//         .catch((err) => {
//             console.error(err);
//             throw err;
//         });
// };

/** Get timeEntry (from - to) */
export const getTimeEntriesBetween = async (token: string, date: { from: any; to: any }) => {
    const now = new Date();
    return fetch(`${timeEntryUrl}/${date.from}/${date.to}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then((res) => {
            const diff = new Date().getTime() - now.getTime();
            console.log("Time took to fetch hours", diff);
            return handleErrors(res);
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

// /** Get hours (from - to) */
// export const getHoursBetween = async (token, date) => {
//     return fetch(
//         `${timeEntryUrl}/hours/${date.from}/${date.to}`,
//         {
//             method: "GET",
//             headers: await getHeader(token),
//         }
//     )
//         .then(handleErrors)
//         .catch((err) => {
//             console.error(err);
//             throw err;
//         });
// };

/** Submit time entry insto DB */
export const submitTimeEntry = async (token: string, body: ITimeEntry) => {
    return fetch(`${timeEntryUrl}`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update existing time entry */
export const updateTimeEntry = async (token: string, body: object, id: string) => {
    return fetch(`${timeEntryUrl}/` + id, {
        method: "PUT",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update existing time entry */
export const updateDescription = async (token: string, body: { description: any }, id: string | undefined) => {
    return fetch(`${timeEntryUrl}/description/` + id, {
        method: "PATCH",
        headers: await getHeader(token),
        body: JSON.stringify(body),
        // referrerPolicy: "no-referrer",
        mode: "cors",
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Delete timeEntry */
export const deleteTimeEntry = async (token: string, id: any, from: any) => {
    return fetch(`${timeEntryUrl}/${id}/${from}`, {
        method: "DELETE",
        headers: await getHeader(token),
        // body: JSON.stringify({}),
    })
        .then(handleErrors)
        .catch((err) => {
            throw err;
        });
};

/** Get projects (for user) */
export const fetchProjects = async (token: string) => {
    return fetch(`${projectUrl}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Get projects based on company_id & projectName */
export const fetchProject = async (token: any, companyID: any, projectName: any) => {
    return fetch(`${projectUrl}/byID/${companyID}/${projectName}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Delete project based on id */
export const deleteProject = async (token: any, id: any) => {
    return fetch(`${projectUrl}/${id}`, {
        method: "DELETE",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

//NOTE TO MYSELF: USE THIS FORMAT FOR ALL CALLS!
/** Fetch project setting based on project name*/
export const getProjectSettings = async (token: string, name: string) => {
    return await fetch(`${projectUrl}/byName/${name}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

//Get project by id
export const getProjectById = async (token: string, id: string | undefined) => {
    return await fetch(`${projectUrl}/${id}`, {
        method: "GET",
        headers: await getHeader(token),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Update existing project settings */
export const updateProjectSettings = async (token: string, body: IProject, id: string | undefined) => {
    return await fetch(`${projectUrl}/` + id, {
        method: "PUT",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/** Create project */
export const createProject = async (token: string, body: IProject) => {
    return fetch(`${projectUrl}`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

/**Import hours from file */
export const importHours = async (token: string, body: { userData: object; entries: {} }) => {
    return fetch(`${companyUrl}/importTimeTable`, {
        method: "POST",
        headers: await getHeader(token),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};
