import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import "../utils/css/App.css";
// import jwt_decode from "jwt-decode";
import * as Constants from "../utils/constants";
import * as Backend from "../actions/backendCalls";
import * as OneDrive from "../actions/oneDrive";
import moment from "moment";

import {
    Button,
    Dropdown,
    // Flex,
    Checkbox,
    Datepicker,
    mergeThemes,
    Provider,
    teamsDarkTheme,
    teamsTheme,
    Popup,
    Loader,
    Text,
    Flex,
    ThemeInput,
    TeamsThemeStylesProps,
    ThemePrepared,
} from "@fluentui/react-northstar";
import { ReportType, REPORT_TYPES } from "./IReportsTab";
import { YearDropdown } from "./YearDropdown";
import CustomLoader from "./CustomLoader";
import { IUser } from "../types/types";
import { customTheme, mobileProviderStyle } from "./ThemeProvider";

/**
 * The 'PersonalTab' component renders the main tab content
 * of your app.
 */
// class ReportsTab extends React.Component {
export default function ReportsTab() {
    const [theme, setTheme] = useState<ThemeInput>();
    const [context, setContext] = useState<microsoftTeams.app.Context>();
    const [jwtoken, setJwtoken] = useState<string>();
    // const [decodedToken, setDecodedToken] = useState<any>({});
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth >= Constants.MOBILEWIDTH ? false : true);
    // const [data, setData] = useState<any[]>([]);
    const [projectsList, setProjectsList] = useState<string[]>([]);
    const [userList, setUserList] = useState<string[]>([]);
    // const [projectsData, setProjectsData] = useState<any[]>([""]);
    const [projectCompanyID, setProjectCompanyID] = useState<Backend.IProjectCompnayID[]>([]);
    const [usersData, setUsersData] = useState<any[]>([]);
    const [companiesList, setCompaniesList] = useState<string[]>([]);
    // const [companiesData, setCompaniesData] = useState<any[]>([""]);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isCompanyDisabled, setIsCompanyDisabled] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<number>();
    const [endDate, setEndDate] = useState<number>();
    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [selectedProjectsName, setSelectedProjectsName] = useState<string[]>([]);
    const [selectedUsersNames, setSelectedUsersNames] = useState<string[]>([]);
    const [selectedUsersIDs, setSelectedUsersIDs] = useState<string[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>();
    const [selectedCompanyName, setSelectedCompanyName] = useState<string>();
    const [companySelected, setCompanySelected] = useState<boolean>(false);
    const [popup, setPopup] = useState<any>(<Loader size="small" label="Saving..." />);
    const [serverSideToken, setServerSideToken] = useState<string>();
    const [selectedDateStart, setSelectedDateStart] = useState<Date>();
    const [selectedDateEnd, setSelectedDateEnd] = useState<Date>();
    const [getAllChecked, setGetAllChecked] = useState<boolean>(false);
    const [reportType, setReportType] = useState<string>();
    const [ready, setReady] = useState<boolean>(false);
    const [datePickerKey, setDatePickerKey] = useState<string>("datePicker");
    const [projectTypeSelected, setProjectTypeSelected] = useState<boolean>(false);
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [userSelected, setUserSelected] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [error /* setError */] = useState<boolean>(false);
    const [themeString, setThemeString] = useState<string>("default");
    const providerStyle = isMobile ? mobileProviderStyle : {};

    /**
     * Updates the state of isMobile
     */
    const updateMobileState = () => {
        setIsMobile(window.innerWidth >= Constants.MOBILEWIDTH ? false : true);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMobileState);
        Backend.getServerSideVersion().then((res) => {
            console.log("Backend version: " + res.version);
            console.log("Frontend version: " + process.env.REACT_APP_VERSION);
        });

        authenticate();

        return () => {
            window.removeEventListener("resize", updateMobileState);
        };
    }, []);

    useEffect(() => {
        if (context) {
            const appTheme = context?.app.theme;
            setThemeString(appTheme);
        }
    }, [context]);

    useEffect(() => {
        const _customTheme = customTheme; //custom theme props
        const _theme: ThemePrepared<TeamsThemeStylesProps> =
            themeString === "dark" || themeString === undefined ? teamsDarkTheme : teamsTheme; //teams theme props

        setTheme(mergeThemes(_theme, _customTheme)); //merge custom theme with teams theme
    }, [themeString]);

    /**
     * Authenticates the user and sets the context and token in the state
     *
     * @returns {Promise<void>}
     */
    const authenticate = async (): Promise<void> => {
        // Get the user context from Teams and set it in the state
        await microsoftTeams.app
            .getContext()
            .then(async (context) => {
                setContext(context);

                // Get Auth token from AD
                const authTokenRequest: microsoftTeams.authentication.AuthTokenRequestParameters = {
                    resources: [],
                    silent: false,
                };

                microsoftTeams.authentication
                    .getAuthToken(authTokenRequest)
                    .then(async (token) => {
                        if (token) {
                            setJwtoken(token);
                            if (!context.user?.tenant?.id) {
                                console.error("Tenant id missing");
                                setErrorMsg(
                                    "Tenant id missing. Try to reload page. If that doesn't help please contact Toni.K or Levi"
                                );
                                return;
                            }
                            await Backend.getServerSideToken(token, context.user?.tenant?.id).then(async (resp) => {
                                if (resp) {
                                    setServerSideToken(resp);
                                }
                            });

                            await getUser(token);
                            await getUsers(token);
                            await getCompanies(token);
                            setReady(true);
                        } else {
                            console.error("Failure: " + token);
                            setErrorMsg(
                                "Login failure. Try to reload page. If that doesn't help please contact Toni.K or Levi"
                            );
                        }
                    })
                    .catch((error) => {
                        console.error("Failure: " + error);
                        setErrorMsg(
                            "Login failure. Try to reload page. If that doesn't help please contact Toni.K or Levi"
                        );
                    });

                microsoftTeams.app.notifySuccess();
            })
            .catch((err) => {
                console.log(`Error while getting context: ${err}`);
                setErrorMsg(
                    `Error while getting context: ${err}. Try to reload page. If that doesn't help please contact Toni or Levi`
                );
            });

        window.addEventListener("onload", updateMobileState);
    };

    //React lifecycle method that gets called once a component has finished mounting
    //Learn more: https://reactjs.org/docs/react-component.html#componentdidmount
    // async componentDidMount() {
    //     const res = await Backend.getServerSideVersion();
    //     console.log("Backend version: " + res.version);
    //     console.log("Frontend version: " + process.env.REACT_APP_VERSION);

    // // Get the user context from Teams and set it in the state
    // microsoftTeams.getContext((context: microsoftTeams.Context) => {
    //     setState({
    //         context: context,
    //     });
    // });

    // microsoftTeams.registerOnThemeChangeHandler((theme) => {
    //     if (theme !== theme) {
    //         setState({ theme });
    //     }
    // });
    // Get Auth token from AD
    // const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
    //     successCallback: async (result: string) => {
    //         try {
    //             const decodedToken = jwt_decode(result);
    //             setState({
    //                 jwtoken: result,
    //                 decodedToken: decodedToken,
    //             });
    //             const resp = await Backend.getServerSideToken(result, context.tid);
    //             setState({ serverSideToken: resp });
    //             await getUser();
    //             await getUsers();
    //             await getCompanies();
    //         } catch (err: any) {
    //             if (err.status === 409) {
    //                 setState({
    //                     error: true,
    //                     errorMsg:
    //                         "Frontend and backend are not in sync. Please wait a few minutes and try again. If that doesn't help please contact Toni.K or Levi",
    //                 });
    //                 return;
    //             }
    //             console.error(err);
    //             setError(true);
    //             setErrorMsg(err);
    //         }
    //     },
    //     failureCallback: function (error: string) {
    //         reject("Failure while getting client side token: " + error);
    //     },
    //     // resources: ["https://graph.microsoft.com"],
    // };
    // microsoftTeams.authentication.getAuthToken(authTokenRequest);

    // window.addEventListener("onload", updateMobileState);
    // microsoftTeams.appInitialization.notifySuccess();
    // }

    // const getUser = async () => {
    //     setReady(false);
    //     if (jwtoken !== "") {
    //         const user = await Backend.getUser(
    //             jwtoken
    //             // decodedToken.oid
    //         );
    //         setUser(user);
    //         setUserSelected(user.is_admin ? false : true);
    //     }
    // };

    /**
     * Gets the user from the database
     *
     * @param token jwt token
     * @param decodedToken the decoded token
     */
    const getUser = async (token: string) => {
        setReady(false);

        await Backend.getUser(token)
            .then((user) => {
                if (user) {
                    setUser(user);
                    setUserSelected(user.is_admin ? false : true);
                } else {
                    console.log("User not found, let's create one");
                    // setNewUser(token);
                }
                return user;
            })
            .catch((err) => {
                if (err.status === 409) {
                    setErrorMsg(
                        "Frontend and backend are not in sync. Please wait a few minutes and try again. If that doesn't help please contact Toni.K or Levi"
                    );
                    return;
                }
                console.error(`Error while getting user: ${err}`);
                setErrorMsg(
                    `Error while getting user: ${err}. Try to reload page. If that doesn't help please contact Toni or Levi`
                );
            });
    };

    const getUsers = async (token: string) => {
        if (token !== "") {
            const listOfUserNames: string[] = [];
            const users = await Backend.getUsers(token);
            users.forEach((user) => {
                listOfUserNames.push(user.full_name);
            });

            if (listOfUserNames === undefined || listOfUserNames.length == 0) {
                console.warn("No Users found");
            }

            setUserList(listOfUserNames);
            setUsersData(users);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const createReportWorkBook = async (serversideToken: string, data: BodyInit, type: string | undefined) => {
        try {
            const date = new Date();
            const year = date.getFullYear();
            const timestamp = moment(date).format("YYYYMMDD-HHmmss");
            if (!user) {
                throw "User not found!";
            }
            const folderName = `Time Tracker/${user.full_name}/${year}`;
            let filename = "";
            let yearlyString = "";

            switch (type) {
                case ReportType.TIME_ENTRIES:
                    break;
                case ReportType.YEAR_REPORT:
                    yearlyString = "YEARLY_REPORT_";
                    break;
                default:
                    break;
            }

            if (selectedProjectsName.length > 0) {
                if (selectedUsersNames.length > 0) {
                    filename = `${selectedCompanyName}_${selectedProjectsName}_${selectedUsersNames}_${timestamp}.xlsx`;
                } else {
                    filename = `${selectedCompanyName}_${selectedProjectsName}_${timestamp}.xlsx`;
                }
            } else {
                if (selectedUsersNames.length > 0) {
                    filename = `${yearlyString}${selectedCompanyName}_all_projects_${selectedUsersNames}_${timestamp}.xlsx`;
                } else {
                    filename = `${yearlyString}${selectedCompanyName}_all_projects_and_users_${timestamp}.xlsx`;
                }
            }

            const response = await OneDrive.uploadFileToOneDrive(serversideToken, folderName, filename, data);
            const fullPath = response.webUrl;
            const simplePath = response.parentReference.path.split(":")[1];
            console.log(fullPath);
            console.log(filename);
            const popup = (
                <p>
                    <Text color="green" weight="bold">
                        Done!
                    </Text>
                    <br />
                    File: <b>{filename}</b> can be found from:
                    <br />
                    <b>{simplePath}</b>
                    <br />
                    <a target={"new"} style={{ color: "orange" }} href={fullPath}>
                        Link to the folder
                    </a>
                </p>
            );
            return popup;
        } catch (error) {
            console.error();
            return error;
        }
    };

    const getReport = async () => {
        try {
            const body: Backend.IFetchHoursBody = {
                company: selectedCompany,
                projects: selectedProjects,
                from: startDate?.valueOf(),
                to: endDate?.valueOf(),
                getAll: getAllChecked,
                users: selectedUsersIDs,
            };
            console.log(body);

            let resp;

            if (jwtoken && serverSideToken) {
                switch (reportType) {
                    case ReportType.TIME_ENTRIES:
                        {
                            console.log(body);

                            resp = await Backend.fetchHours(jwtoken, body); //Creates time entries report in backend (returs stream)
                        }
                        break;

                    case ReportType.YEAR_REPORT:
                        {
                            /** Get firt and last date of the current year */
                            const firstDay = new Date(year, 0, 1);
                            const lastDay = new Date(year, 11, 31);

                            body.from = firstDay.valueOf();
                            body.to = lastDay.valueOf();

                            /** This flag determines if we want to have all user information or just company specific infomation */
                            if (selectedCompanyName === "==OFFCODE_USERS==") {
                                resp = await Backend.fetchYearlyTotalOffcode(jwtoken, body);
                            } else {
                                resp = await Backend.fetchYearlyTotal(jwtoken, body); // This call uses backend to create the report (faster)
                            }
                        }
                        break;

                    default:
                        break;
                }

                if (resp.error) throw resp.error;
                const respPopup = await createReportWorkBook(serverSideToken, resp, reportType);
                setPopup(respPopup);
            } else {
                throw "Token missing!";
            }
        } catch (error: any) {
            console.error(error);
            setPopup(
                <Text color="red" weight="bold">
                    {JSON.stringify(error.error)}
                </Text>
            );
        }
    };

    // const getProjects = async (selectedCompanyID: string) => {
    //     const listOfProjects: any = [];
    //     let disabled = false;
    //     try {
    //         if (!jwtoken) {
    //             setErrorMsg("Token missing!");
    //             return;
    //         }
    //         const projects = await Backend.getProjects(jwtoken, selectedCompanyID);
    //         projects.forEach((project) => {
    //             listOfProjects.push(project.project_name);
    //         });

    //         if (listOfProjects === undefined || listOfProjects.length == 0) {
    //             console.log("No projects for this company available");
    //             disabled = true;
    //             setCompanySelected(false);
    //         }

    //         setProjectsList(listOfProjects);
    //         setProjectsData(projects);
    //         setSelectedCompany(selectedCompanyID);
    //         setIsDisabled(disabled);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const getCompanies = async (token: string) => {
        let companyDisabled = false;
        setReady(false);

        const res = await Backend.fetchHasHours(token);
        console.log(res);

        /**Check that companies is not empty */
        if (res.companies === undefined || res.companies.length == 0) {
            console.log("No companies found");
            companyDisabled = true;
            setCompanySelected(false);
        } else {
            companyDisabled = false;
        }

        setCompaniesList(res.companies);
        setProjectCompanyID(res.projectCompanyID);
        setIsCompanyDisabled(companyDisabled);
        // setReady(true);
    };

    const getCompanyID = (companyName: string) => {
        if (!jwtoken) {
            setErrorMsg("Token missing!");
            return;
        }
        console.log(companyName);

        Backend.getCompanyByName(jwtoken, companyName)
            .then((company) => {
                // getProjects(response[0].id);
                const projects: string[] = [];
                console.log(company);

                projectCompanyID.forEach((project) => {
                    if (project.company_id === company.id) {
                        projects.push(project.project_name);
                    }
                });
                console.log(projects);

                setSelectedCompany(company.id);
                setProjectsList(projects);
                setIsDisabled(false);
            })
            .catch((err) => console.error(err));
    };

    const handleCompanyChange = (d: any) => {
        if (d.value) {
            setSelectedCompanyName(d.value);
            if (d.value !== "==OFFCODE_USERS==" && d.value !== "== ALL ==") {
                getCompanyID(d.value);
            } else {
                setSelectedCompany(undefined); // Clear selected company
            }
            setCompanySelected(true);
        } else {
            clearFields();
        }
    };

    const handleProjectChange = (e: any, d: any = []) => {
        const projectIDs: string[] = [];

        for (const val in d.value) {
            projectCompanyID.forEach((item) => {
                if (item.project_name === d.value[val]) {
                    projectIDs.push(item.project_id);
                }
            });
        }

        console.log(projectIDs);

        setSelectedProjectsName(d.value);
        setSelectedProjects(projectIDs);
    };

    const handleUserChange = (e: any, d: any = []) => {
        const uIDArr: string[] = [];

        /** This is for multiselect */
        // for (const val in d.value) {
        //     for (const user in usersData) {
        //         if (usersData[user].full_name === d.value[val]) {
        //             uIDArr.push(usersData[user].id);
        //         }
        //     }
        // }

        if (d.value === null) d.value = undefined;

        for (const user in usersData) {
            if (usersData[user].full_name === d.value) {
                uIDArr.push(usersData[user].id);
            }
        }

        setSelectedUsersNames(d.value);
        setSelectedUsersIDs(uIDArr);
        setUserSelected(d.value ? true : false);
    };

    const handleReportTypeChange = (e: any, d: any = []) => {
        if (d.value === null) d.value = undefined;

        /** ==OFFCODE_USERS== are only user to get yearly report. Remove it otherwise*/
        if (companiesList[0] === "==OFFCODE_USERS==" || companiesList[0] === "== ALL ==") {
            const modifiedCompanyList = companiesList;
            modifiedCompanyList.shift();
            setCompaniesList(modifiedCompanyList);
        }

        setReportType(d.value);
        setProjectTypeSelected(d.value ? true : false);
        setUserSelected(true);

        const modifiedCompanyList = companiesList;

        if (d.value === ReportType.YEAR_REPORT) {
            modifiedCompanyList.unshift("==OFFCODE_USERS==");
        }

        if (d.value === ReportType.TIME_ENTRIES) {
            modifiedCompanyList.unshift("== ALL ==");
        }

        setSelectedDateStart(undefined);
        setSelectedDateEnd(undefined);
        setDatePickerKey(`datepickerKey${new Date()}`); //This is the way to trigger datepicker re-render
        setUserSelected(true);
        setCompaniesList(modifiedCompanyList);
    };

    const clearFields = () => {
        setPopup("");
        setSelectedCompany(undefined);
        setSelectedCompanyName(undefined);
        setSelectedProjects([]);
        setSelectedProjectsName([]);
        setSelectedUsersIDs([]);
        setSelectedUsersNames([]);
        setCompanySelected(false);
        setIsDisabled(true);
        setStartDate(undefined);
        setEndDate(undefined);
        setSelectedDateStart(undefined);
        setSelectedDateEnd(undefined);
        setUserSelected(false);
    };

    const handleYearChange = (year: number) => {
        console.log(`year: ${year}`);
        setYear(year);
    };
    return (
        <Provider theme={theme} style={providerStyle}>
            {!error ? (
                <div>
                    {ready ? (
                        <div
                            style={{
                                paddingLeft: 50,
                                paddingRight: 50,
                                paddingBottom: 50,
                                display: "inlineBlock",
                            }}
                        >
                            <Flex gap="gap.large">
                                <Flex.Item size="size.half">
                                    <div>
                                        <h3>Download project reports</h3>
                                        <p></p>
                                        <div style={{ maxWidth: "250px" }}>
                                            <Dropdown
                                                // disabled={projectTypeSelected}
                                                items={REPORT_TYPES}
                                                placeholder="Select report type"
                                                checkable
                                                clearable
                                                // multiple
                                                fluid
                                                value={reportType}
                                                getA11ySelectionMessage={{
                                                    onAdd: (item) => `${item} has been selected.`,
                                                }}
                                                onChange={handleReportTypeChange}
                                            />
                                            {projectTypeSelected ? (
                                                <>
                                                    <p></p>
                                                    <Dropdown
                                                        disabled={isCompanyDisabled}
                                                        items={companiesList}
                                                        placeholder="Select company"
                                                        // checkable
                                                        clearable
                                                        fluid
                                                        getA11ySelectionMessage={{
                                                            onAdd: (item) => `${item} has been selected.`,
                                                        }}
                                                        onChange={(e, d) => {
                                                            if (d) handleCompanyChange(d);
                                                        }}
                                                        value={selectedCompanyName}
                                                    />
                                                    <p></p>
                                                    {reportType === ReportType.YEAR_REPORT ? (
                                                        <>
                                                            <p></p>
                                                            <YearDropdown
                                                                projectChangeParentHandler={handleYearChange}
                                                            />
                                                            <p></p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Dropdown
                                                                disabled={isDisabled}
                                                                items={projectsList}
                                                                placeholder="Select project"
                                                                checkable
                                                                multiple
                                                                fluid
                                                                value={selectedProjectsName}
                                                                getA11ySelectionMessage={{
                                                                    onAdd: (item) => `${item} has been selected.`,
                                                                }}
                                                                onChange={handleProjectChange}
                                                            />
                                                            <p></p>
                                                            <Datepicker
                                                                inputPlaceholder="Select start date"
                                                                onDateChange={(e, data) => {
                                                                    if (data) {
                                                                        setStartDate(data.value?.valueOf());
                                                                        setSelectedDateStart(data.value);
                                                                    }
                                                                }}
                                                                selectedDate={selectedDateStart}
                                                                disabled={!companySelected}
                                                                input={{
                                                                    clearable: true,
                                                                }}
                                                                key={`start${datePickerKey}`}
                                                            />
                                                            <p></p>
                                                            <Datepicker
                                                                inputPlaceholder="Select end date"
                                                                onDateChange={(e, data) => {
                                                                    if (data) {
                                                                        setEndDate(
                                                                            data.value?.valueOf() +
                                                                                (23 * 60 + 59) * 60 * 1000
                                                                        );
                                                                        setSelectedDateEnd(data.value);
                                                                    }
                                                                }}
                                                                selectedDate={selectedDateEnd}
                                                                disabled={!companySelected}
                                                                input={{
                                                                    clearable: true,
                                                                }}
                                                                key={`end${datePickerKey}`}
                                                            />

                                                            {user && (user.is_admin || user.is_project_manager) ? (
                                                                <>
                                                                    <p></p>
                                                                    <Checkbox
                                                                        disabled={!companySelected}
                                                                        label="Check to collect everybody"
                                                                        toggle
                                                                        onChange={(e, d) => {
                                                                            if (d) {
                                                                                setGetAllChecked(d.checked);
                                                                                setSelectedUsersNames([]);
                                                                                setSelectedUsersIDs([]);
                                                                                setUserSelected(d.checked);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <p></p>
                                                                    <Dropdown
                                                                        disabled={!companySelected || getAllChecked}
                                                                        items={userList}
                                                                        placeholder="Select user"
                                                                        checkable
                                                                        clearable
                                                                        // multiple
                                                                        fluid
                                                                        value={selectedUsersNames}
                                                                        getA11ySelectionMessage={{
                                                                            onAdd: (item) =>
                                                                                `${item} has been selected.`,
                                                                        }}
                                                                        onChange={handleUserChange}
                                                                    />
                                                                    <p></p>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </>
                                            ) : null}
                                            <p></p>
                                        </div>
                                        <Popup
                                            position-align="above-start"
                                            content={popup}
                                            trigger={
                                                <Button
                                                    disabled={!companySelected || !reportType || !userSelected}
                                                    onClick={getReport}
                                                    content="Download"
                                                />
                                            }
                                            onOpenChange={(e, d) => {
                                                if (d && !d.open) {
                                                    // clearFields();
                                                    setPopup("");
                                                }
                                            }}
                                        />
                                    </div>
                                </Flex.Item>
                            </Flex>
                        </div>
                    ) : (
                        <CustomLoader msg="Fetching data..." />
                    )}
                </div>
            ) : (
                <div
                    style={{
                        minHeight: "100vh",
                        minWidth: "100vw",
                    }}
                >
                    <Text
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                        weight="bold"
                        size="large"
                        content={errorMsg}
                    />
                </div>
            )}
        </Provider>
    );
}
