import React from "react";
import "../utils/css/App.css";

/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class TermsOfUse extends React.Component {
    render() {
        return (
            <div>
                <h1>Terms of Use</h1>
            </div>
        );
    }
}

export default TermsOfUse;
