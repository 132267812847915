import React from "react";
import { components, GroupBase, IndicatorsContainerProps, IndicatorSeparatorProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import { sortOn } from "../utils/helpers";
import { IDropdownElement } from "./WeekTable";

const PRIVATE = 0;
const PUBLIC = 1;

interface creatableProjectSearchInterface {
    ProjectList: IDropdownElement[] | null;
    SelectedProject: any;
    PassSelectedProject: any;
    Styles?: any;
    SetPopUp: any;
}

export default class CreatableProjectSearch extends React.Component<creatableProjectSearchInterface> {
    state: {
        options: IDropdownElement[] | null;
        jwtoken: string;
        styles: any;
        value: any;
    };
    constructor(props: creatableProjectSearchInterface) {
        super(props);
        this.state = {
            options: null,
            jwtoken: "",
            styles: this.props.Styles,
            value: null,
        };
    }

    customStyles = {
        overflow: "scroll",
        container: (provided: any) => ({
            ...provided,
            // width: "50%",
            // padding: 10,
            margin: -2,
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
        }),
        // eslint-disable-next-line no-unused-vars
        option: (provided: any, { isFocused, data }: any) => ({
            ...provided,
            // borderBottom: "1px dotted pink"
            // color: isFocused ? "#F3F2F1" : null,
            color: data.private
                ? this.state.styles.siteVariables.colors.green["200"]
                : this.state.styles.siteVariables.colors.orange["300"],
            backgroundColor: isFocused
                ? this.state.styles.siteVariables.colorScheme.brand.backgroundHover1
                : this.state.styles.siteVariables.bodyBackground,
        }),
        control: (provided: any) => ({
            ...provided,
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
            borderStyle: "none",
            borderRadius: 0,
            outline: "none",
            boxShadow: "none",
            flexDirection: "row-reverse",
            padding: "5px",
            // width: "100%",
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
            fontSize: "0.9rem",
        }),
        singleValue: (provided: any) => ({
            ...provided,
            // color: this.state.styles.siteVariables.colorScheme.brand
            //     .backgroundHover,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
        }),
        input: (provided: any) => ({
            ...provided,
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
        }),
        groupHeading: (provided: any) => ({
            ...provided,
            // backgroundColor:
            //     this.state.styles.siteVariables.colorScheme.brand
            //         .backgroundHover,
            // flex: "1 1",
            fontSize: "0.8rem",
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
            margin: 0,
        }),
    };

    componentDidMount() {}
    componentDidUpdate(prevProps: { ProjectList: IDropdownElement[] | null }) {
        if (prevProps.ProjectList !== this.props.ProjectList || !this.state.options) {
            interface Group {
                label: string;
                options: any[];
            }
            const groups: Group[] = [
                { label: "private", options: [] },
                { label: "public", options: [] },
            ];
            if (this.props.ProjectList) {
                this.props.ProjectList.forEach((project) => {
                    if (project.private) {
                        groups[PRIVATE].options.push(project);
                    } else {
                        groups[PUBLIC].options.push(project);
                    }
                });
            }
            sortOn(groups[PRIVATE].options, "label");
            sortOn(groups[PUBLIC].options, "label");
            this.setState({ options: groups });
        }
    }

    handleChange = async (newValue: any) => {
        if (newValue) {
            if (newValue["__isNew__"] && newValue["__isNew__"] === true) {
                console.log("Create new project!");
                this.props.SetPopUp(true);
                this.setState({ value: null });
                this.props.PassSelectedProject(newValue);
            } else {
                // console.log("Lets add this project");
                this.props.SelectedProject(newValue);
            }
        }
    };

    render() {
        // console.log(this.state.styles);
        // console.log(Group);
        return (
            <CreatableSelect
                // maxMenuHeight={160}
                styles={this.customStyles}
                onChange={this.handleChange}
                options={this.state.options!}
                getOptionLabel={(option: IDropdownElement) => `${option.label}: ${option.value}`} //create label for options (company & project name)
                placeholder="Select project..."
                noOptionsMessage={() => "Add new project by typing its name"}
                value={this.state.value}
                components={{
                    IndicatorSeparator,
                    IndicatorsContainer,
                    // Group,
                }}
                formatCreateLabel={() => `Create new Project`}
            />
        );
    }
}

const indicatorStyle = {
    backgroundColor: "none",
};

const IndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps<any>) => {
    return <span style={indicatorStyle} {...innerProps} />;
};

const IndicatorsContainer = (
    props: JSX.IntrinsicAttributes & IndicatorsContainerProps<unknown, boolean, GroupBase<unknown>>
) => {
    return (
        <div style={indicatorStyle}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

// const groupStyles = {
//     // border: `2px dotted red`,
//     borderRadius: "5px",
//     // color: "green",
//     // background:"",
// };

// const Group = (props) => (
//     <div style={groupStyles}>
//         <components.Group {...props} />
//     </div>
// );
