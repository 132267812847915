import React, { useState, useEffect /* SetStateAction */ } from "react";
import {
    Dialog,
    Form,
    Divider,
    RadioGroup,
    FormButton,
    FormCheckbox,
    CheckboxProps,
    Provider,
    Flex,
    FlexItem,
    Button,
    EditIcon,
    Text,
    Pill,
    RadioGroupItemProps,
    AddIcon,
    Input,
    TrashCanIcon,
    Tooltip,
    CloseIcon,
    ThemePrepared,
    TeamsThemeStylesProps,
} from "@fluentui/react-northstar";
import { DatePicker, createTheme, ThemeProvider } from "@fluentui/react";
import * as Backend from "../actions/backendCalls";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { IProject } from "../types/types";
initializeIcons(/* optional base url */);

type configureProjectDialogTypes = {
    projectName: string | undefined;
    projectId: string | undefined;
    jwtoken: string;
    selected: boolean;
    decodedToken: any;
    admin: boolean;
    manager: boolean;
};

const myTheme = createTheme({
    palette: {
        themePrimary: "#6264a7",
        themeLighterAlt: "#040407",
        themeLighter: "#10101b",
        themeLight: "#1d1e32",
        themeTertiary: "#3b3c63",
        themeSecondary: "#565892",
        themeDarkAlt: "#6e70af",
        themeDark: "#8183bb",
        themeDarker: "#9ea0cd",
        neutralLighterAlt: "#323130",
        neutralLighter: "#3a3938",
        neutralLight: "#484645",
        neutralQuaternaryAlt: "#504f4d",
        neutralQuaternary: "#575554",
        neutralTertiaryAlt: "#747270",
        neutralTertiary: "#f3f1f0",
        neutralSecondary: "#f5f4f2",
        neutralPrimaryAlt: "#f7f6f5",
        neutralPrimary: "#edebe9",
        neutralDark: "#fbfafa",
        black: "#979593",
        white: "#292827",
    },
});

const ConfigureProjectDialog = (props: configureProjectDialogTypes) => {
    const [disabled, setDisabled] = useState<boolean>(true);
    const [projectName, setProjectName] = useState<string>("");
    const [popup, setPopup] = useState<boolean>(false);
    const [projectSettings, setProjectSettings] = useState<IProject | null>(null);
    const [isPrivate, setIsPrivate] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [locked, setLocked] = useState<boolean | undefined | "mixed">(false);
    const [tags, setTags] = useState<string[]>(["Add"]);
    const [memberList, setMemberList] = useState<string[]>([]);
    const [input, setInput] = useState("");
    const [alwaysOpen, setAlwaysOpen] = useState<boolean>(true);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [budgetTotal, setBudgetTotal] = useState<number | null>(null);
    const [budgetUsed, setBudgetUsed] = useState<number | null>(null);
    const [projectId, setProjectId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (projectSettings !== null) {
            console.log("projectSettings", projectSettings);
            const _p = projectSettings;
            const activeFrom = _p.active.from!;
            const activeTo = _p.active.to!;
            if (activeTo === null && activeFrom === null) setAlwaysOpen(true);
            else {
                setAlwaysOpen(false);
                setStartDate(new Date(activeFrom));
                setEndDate(new Date(activeTo));
            }

            setLocked(_p.entry_locked);
            setIsPrivate(_p.private);
            setTags(_p.tags);
            setMemberList(_p.members);
            if (_p.budget) {
                setBudgetUsed(_p.budget.used);
                setBudgetTotal(_p.budget.total);
            }
        }
    }, [projectSettings]);

    useEffect(() => {
        if (props.projectName) setProjectName(props.projectName);
    }, [props.projectName]);

    useEffect(() => {
        if (props.projectId) setProjectId(props.projectId);
        // console.log("projectId", props.projectId);
    }, [props.projectId]);

    useEffect(() => {
        setDisabled(!props.selected);
    }, [props.selected]);

    const handleProjectEdit = async () => {
        try {
            // const settings = await Backend.getProjectSettings(props.jwtoken, props.projectName);
            const settings = await Backend.getProjectById(props.jwtoken, props.projectId);
            setProjectSettings(settings);
            console.log(settings);
            setPopup(true);
        } catch (error) {
            console.error(error);
            setPopup(false);
        }
    };

    const handleSubmit = async () => {
        try {
            if (projectSettings !== null) {
                console.log(`Project: ${projectName}`);
                console.log(`  Start date: ${startDate}`);
                console.log(`  End date: ${endDate}`);
                console.log(`  Locked: ${locked}`);
                console.log(`  Tags: ${tags}`);
                console.log(`  Private: ${isPrivate}`);
                console.log(`  Owner OID: ${projectSettings.owner_oid}`);
                console.log(`  Members: ${memberList}`);
                console.log(`  AlwaysOpen: ${alwaysOpen}`);
                console.log(`  Budget total: ${budgetTotal}`);
                console.log(`  Budget used: ${budgetUsed}`);

                const body: IProject = projectSettings; //Init with prev values
                // const settings = await Backend.getProjectById(props.jwtoken, props.projectId);
                // setProjectSettings(settings);
                // console.log(settings);
                body.project_name = projectName;
                body.entry_locked = locked ? true : false;
                body.tags = tags;
                body.private = isPrivate;
                body.members = memberList;
                body.active.from = startDate ? startDate.valueOf() : null;
                body.active.to = endDate ? endDate.valueOf() : null;

                // If budget is missing add it manually
                if (!body.budget || !body.budget.total || !body.budget.used) {
                    const budget = { total: null, used: null };
                    body.budget = budget;
                }
                body.budget.total = budgetTotal;
                body.budget.used = budgetUsed;

                // If closet or set to always open set active hours to null
                if (alwaysOpen && !locked) {
                    body.active.from = null;
                    body.active.to = null;
                }
                console.log("Send body:");
                console.log(body);
                const ret = await Backend.updateProjectSettings(props.jwtoken, body, projectId);
                console.log("Ret: ");
                console.log(ret);
            } else {
                throw new Error("Project settings is null");
            }
        } catch (error) {
            console.error(error);
        }
        setPopup(false);
    };

    const handleAddTag = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        const trimmedInput = input.trim();
        console.log(trimmedInput);
        setTags((prevState) => [...prevState, trimmedInput]);
        setInput("");
    };
    const handleDeleteProject = () => {
        console.log("Delete clicked");
        setShowAlert(true);
    };

    const handleAlertCancel = () => {
        console.log("CANCEL");
        setShowAlert(false);
    };

    const handleAlertConfirm = () => {
        console.log("CONFIRM");
        setShowAlert(false);
    };

    return (
        <Provider.Consumer
            render={(theme: ThemePrepared<TeamsThemeStylesProps>) => (
                <Dialog
                    key="dialog"
                    backdrop
                    open={popup}
                    trapFocus
                    closeOnOutsideClick={false}
                    styles={{
                        color: theme.siteVariables.colorScheme.brand.foreground,
                        overflow: "auto",
                    }}
                    content={
                        <Form
                            onSubmit={async (e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={() => {
                                setPopup(false);
                            }}
                        >
                            <Flex gap="gap.large" column={false}>
                                <FlexItem>
                                    {/* <Text color={"red"} weight="bold" size="largest" content={`${projectName}`} /> */}
                                    <Input
                                        variables={{
                                            inputPadding: "0.5rem",
                                        }}
                                        input={{
                                            style: {
                                                textAlign: "left",
                                                fontSize: "30px",
                                                fontWeight: "bold",
                                                minHeight: "50px",
                                                maxWidth: "80%",
                                                color: !isPrivate ? "grey" : theme.siteVariables.colors.red["400"],
                                            },
                                        }}
                                        autoComplete="off"
                                        type="text"
                                        placeholder={`${projectName}`}
                                        id={`${projectName}-id`}
                                        value={projectName}
                                        onChange={(e) => {
                                            const target = e.target as HTMLTextAreaElement;
                                            setProjectName(target.value);
                                        }}
                                        disabled={!isPrivate}
                                        inline
                                    />
                                </FlexItem>
                                <Dialog
                                    trapFocus
                                    backdrop
                                    closeOnOutsideClick={false}
                                    open={showAlert}
                                    onCancel={handleAlertCancel}
                                    onConfirm={handleAlertConfirm}
                                    confirmButton="Confirm"
                                    content="Are you sure you want to delete this project? If you are not 100% sure, don't do this!"
                                    header="Action confirmation"
                                    headerAction={{
                                        icon: <CloseIcon />,
                                        title: "Close",
                                        onClick: handleAlertCancel,
                                    }}
                                    trigger={
                                        <FlexItem>
                                            <Tooltip
                                                trigger={
                                                    <FormButton
                                                        disabledFocusable={!isPrivate}
                                                        primary
                                                        id="button_delete"
                                                        type="button"
                                                        iconOnly
                                                        size="medium"
                                                        key="deleteProjectIconButton"
                                                        icon={<TrashCanIcon />}
                                                        onClick={handleDeleteProject}
                                                    />
                                                }
                                                content={"Delete project. (Only available for private projects"}
                                            />
                                        </FlexItem>
                                    }
                                />
                            </Flex>
                            <Divider />
                            {props.admin || props.manager ? (
                                <>
                                    <Flex gap="gap.medium" column>
                                        <Text weight="bold" size="large" content="Set active hours / locked state" />
                                        <FormCheckbox
                                            label="Entry locked"
                                            toggle
                                            onChange={(e, data: CheckboxProps = {}) => {
                                                setLocked(data.checked);
                                                setAlwaysOpen(false);
                                            }}
                                            disabled={disabled || !props.admin}
                                            checked={locked ? true : false}
                                        />
                                        {locked ? (
                                            <></>
                                        ) : (
                                            <FlexItem>
                                                <FormCheckbox
                                                    label="Always open"
                                                    toggle
                                                    onChange={(e, data: CheckboxProps = {}) => {
                                                        setAlwaysOpen(data.checked ? true : false);
                                                    }}
                                                    disabled={disabled || !props.admin}
                                                    checked={alwaysOpen ? true : false}
                                                />
                                            </FlexItem>
                                        )}
                                        {locked || alwaysOpen ? (
                                            <></>
                                        ) : (
                                            <FlexItem>
                                                <ThemeProvider
                                                    style={{
                                                        maxWidth: "200px",
                                                    }}
                                                    applyTo="body"
                                                    theme={myTheme}
                                                >
                                                    <DatePicker
                                                        label="Select active start date"
                                                        onSelectDate={(date) => {
                                                            if (date) setStartDate(date);
                                                        }}
                                                        disabled={locked || (alwaysOpen ? true : false) || !props.admin}
                                                        initialPickerDate={startDate === null ? undefined : startDate}
                                                        showGoToToday
                                                        allowTextInput
                                                        value={startDate === null ? undefined : startDate}
                                                        ariaLabel="Select startdate"
                                                        isRequired
                                                    />
                                                    <DatePicker
                                                        label="Select active end date"
                                                        onSelectDate={(date) => {
                                                            if (date) setEndDate(date);
                                                        }}
                                                        disabled={locked || (alwaysOpen ? true : false) || !props.admin}
                                                        initialPickerDate={endDate === null ? undefined : endDate}
                                                        showGoToToday
                                                        allowTextInput
                                                        value={endDate === null ? undefined : endDate}
                                                        ariaLabel="Select enddate"
                                                        isRequired
                                                    />
                                                </ThemeProvider>
                                            </FlexItem>
                                        )}
                                    </Flex>
                                    <RadioGroup
                                        vertical
                                        defaultCheckedValue={isPrivate ? "private" : "public"}
                                        items={
                                            props.admin
                                                ? ["private", "public"].map((group) => ({
                                                      key: group,
                                                      value: group,
                                                      name: "group",
                                                      label: group,
                                                      "aria-label": "orange",
                                                  }))
                                                : []
                                        }
                                        onCheckedValueChange={(e, p: RadioGroupItemProps | undefined) => {
                                            const members: string[] = [];
                                            if (p!.value == "public") {
                                                members.push("offcodeGroup");
                                                setIsPrivate(false);
                                            } else {
                                                members.push(props.decodedToken.oid);
                                                setIsPrivate(true);
                                            }
                                            setMemberList(members);
                                        }}
                                    />
                                    <Divider />
                                    <Text weight="bold" size="large" content="Tags" />
                                    <Flex gap="gap.small">
                                        <Input
                                            value={input}
                                            placeholder="Enter new tag"
                                            onChange={(e, data) => {
                                                if (data) setInput(data.value);
                                            }}
                                            disabled={!props.admin}
                                        />
                                        <Button
                                            disabledFocusable={disabled || !props.admin}
                                            iconOnly
                                            size="medium"
                                            key="editCompanyNameButton"
                                            icon={<AddIcon />}
                                            id="1"
                                            onClick={handleAddTag}
                                        />
                                    </Flex>
                                    <Flex gap="gap.medium">
                                        {tags.map((e, i) => {
                                            return (
                                                <Pill
                                                    disabled={!props.admin}
                                                    key={`${e}_${i}`}
                                                    actionable
                                                    size={"small"}
                                                    onDismiss={(e, data) => {
                                                        if (data) {
                                                            setTags(tags.filter((e) => e !== data.content)); //remove tag from tags
                                                        }
                                                    }}
                                                >
                                                    {e}
                                                </Pill>
                                            );
                                        })}
                                    </Flex>
                                    <Divider />
                                </>
                            ) : null}
                            <Text
                                // color={"red"}
                                weight="bold"
                                size="large"
                                content={"Budget"}
                            />
                            <Input
                                label="Total"
                                value={budgetTotal?.toString()}
                                disabled={disabled || !props.admin}
                                type={"number"}
                                onChange={(e: any) => setBudgetTotal(parseFloat(e.target.value))}
                                placeholder="Enter budget value.."
                            />
                            <Input label="Used" value={budgetUsed?.toString()} disabled placeholder="0" />
                            <Divider />

                            <Flex>
                                <FormButton
                                    primary
                                    content="Submit"
                                    disabled={disabled || !props.admin}
                                    id="button_submit"
                                    type="submit"
                                />
                                <FlexItem push>
                                    <FormButton secondary content="Cancel" id="button_cancel" type="reset" />
                                </FlexItem>
                            </Flex>
                        </Form>
                    }
                    header={<div>Configure project settings</div>}
                    trigger={
                        <Button
                            disabledFocusable={disabled}
                            key="editCompanyNameButton"
                            icon={<EditIcon />}
                            id="1"
                            onClick={handleProjectEdit}
                            disabled={disabled}
                            content="Edit"
                        />
                    }
                />
            )}
        />
    );
};

export { ConfigureProjectDialog };
