import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

import "../utils/css/App.css";
import "../utils/css/Calendar.css";
// import decode, { JwtPayload } from "jwt-decode";

import * as Constants from "../utils/constants";
import {
    Provider,
    mergeThemes,
    teamsTheme,
    teamsDarkTheme,
    Text,
    ThemeInput,
    ThemePrepared,
    TeamsThemeStylesProps,
} from "@fluentui/react-northstar";

import * as Backend from "../actions/backendCalls";
import WeekTable from "./WeekTable";

import { customTheme, mobileProviderStyle /* customMobileTheme */ } from "./ThemeProvider";
import CustomLoader from "./CustomLoader";
import { IUser } from "../types/types";

// interface IDecodedToken extends JwtPayload {
//     name: string;
//     upn: string;
//     oid: string;
// }

export default function Tab() {
    const [theme, setTheme] = useState<ThemeInput>();
    const [themeString, setThemeString] = useState<string>("default");
    const [context, setContext] = useState<microsoftTeams.app.Context>();
    const [jwtoken, setJwtoken] = useState<string>("");
    // const [decodedToken, setDecodedToken] = useState<IDecodedToken>();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth >= Constants.MOBILEWIDTH ? false : true);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [user, setUser] = useState<IUser | null>(null);
    const providerStyle = isMobile ? mobileProviderStyle : {};

    useEffect(() => {
        window.addEventListener("resize", updateMobileState);
        authenticate();

        microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
            console.log("Theme Changed: ", theme);
            setThemeString(theme);
        });

        return () => {
            window.removeEventListener("resize", updateMobileState);
        };
    }, []);

    useEffect(() => {
        if (context) {
            const appTheme = context?.app.theme;
            setThemeString(appTheme);
        }
    }, [context]);

    useEffect(() => {
        const _customTheme = customTheme; //custom theme props
        const _theme: ThemePrepared<TeamsThemeStylesProps> =
            themeString === "dark" || themeString === undefined ? teamsDarkTheme : teamsTheme; //teams theme props

        setTheme(mergeThemes(_theme, _customTheme)); //merge custom theme with teams theme
    }, [themeString]);

    /**
     * Gets the user from the database
     *
     * @param token jwt token
     * @param decodedToken the decoded token
     */
    const getUser = async (token: string) => {
        // const decodedToken = decode(token) as IDecodedToken;
        // setDecodedToken(decodedToken);

        await Backend.getUser(token)
            .then((user) => {
                if (user) {
                    setUser(user);
                } else {
                    console.log("User not found, let's create one");
                    // setNewUser(token);
                }
                return user;
            })
            .catch((err) => {
                if (err.status === 409) {
                    setErrorMsg(
                        "Frontend and backend are not in sync. Please wait a few minutes and try again. If that doesn't help please contact Toni.K or Levi"
                    );
                    return;
                }
                console.error(`Error while getting user: ${err}`);
                setErrorMsg(
                    `Error while getting user: ${err}. Try to reload page. If that doesn't help please contact Toni or Levi`
                );
            });
    };

    // /**
    //  *  Sets a new user in the database
    //  *
    //  * @param token the jwt token
    //  */
    // const setNewUser = async (token: string) => {
    //     if (!decodedToken) {
    //         console.error("No decoded token found");
    //         return;
    //     }

    //     const body = {
    //         app_settings: { theme: theme, visible_projects: [] },
    //         full_name: decodedToken.name,
    //         email: decodedToken.upn,
    //         oid: decodedToken.oid,
    //         disabled: false, //TODO:??
    //         is_admin: false, //TODO:??
    //     };

    //     Backend.setNewUser(token, body)
    //         .then(() => {
    //             // setUserExists(true);
    //         })
    //         .catch((err) => {
    //             console.error(`Error while setting new user: ${err}`);
    //             setErrorMsg(err);
    //         });
    // };

    /**
     * Updates the state of isMobile
     */
    const updateMobileState = () => {
        setIsMobile(window.innerWidth >= Constants.MOBILEWIDTH ? false : true);
    };

    /**
     * Authenticates the user and sets the context and token in the state
     *
     * @returns {Promise<void>}
     */
    const authenticate = async (): Promise<void> => {
        // Get the user context from Teams and set it in the state
        await microsoftTeams.app
            .getContext()
            .then(async (context) => {
                setContext(context);

                // Get Auth token from AD
                const authTokenRequest: microsoftTeams.authentication.AuthTokenRequestParameters = {
                    resources: [],
                    silent: false,
                };

                microsoftTeams.authentication
                    .getAuthToken(authTokenRequest)
                    .then(async (response) => {
                        if (response) {
                            setJwtoken(response);
                            await getUser(response);
                        } else {
                            console.error("Failure: " + response);
                            setErrorMsg(
                                "Login failure. Try to reload page. If that doesn't help please contact Toni.K or Levi"
                            );
                        }
                    })
                    .catch((error) => {
                        console.error("Failure: " + error);
                        setErrorMsg(
                            "Login failure. Try to reload page. If that doesn't help please contact Toni.K or Levi"
                        );
                    });

                microsoftTeams.app.notifySuccess();
            })
            .catch((err) => {
                console.log(`Error while getting context: ${err}`);
                setErrorMsg(
                    `Error while getting context: ${err}. Try to reload page. If that doesn't help please contact Toni or Levi`
                );
            });

        window.addEventListener("onload", updateMobileState);
    };

    return (
        <Provider theme={theme} style={providerStyle}>
            {!errorMsg ? (
                <div
                    style={{
                        padding: 20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                        overflow: "hidden",
                    }}
                >
                    {!user ? <CustomLoader msg="Fetching users" /> : <WeekTable token={jwtoken} user={user} />}
                </div>
            ) : (
                <div
                    style={{
                        height: "100vh",
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Text
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                        weight="bold"
                        size="large"
                        content={errorMsg}
                    />
                </div>
            )}
        </Provider>
    );
}
