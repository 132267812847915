import React from "react";
import CreatableSelect from "react-select/creatable";
import * as microsoftTeams from "@microsoft/teams-js";
import * as Backend from "../actions/backendCalls";

interface CreatableCompanySearchInterface {
    SelectedCompany: any;
    Styles?: any;
    HandleDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export default class CreatableCompanySearch extends React.Component<CreatableCompanySearchInterface> {
    state: {
        companies: any[];
        jwtoken: string;
        styles: any;
    };
    constructor(props: CreatableCompanySearchInterface) {
        super(props);
        // console.log(this.props.Styles);
        this.state = {
            companies: [],
            jwtoken: "",
            styles: this.props.Styles,
        };
    }

    customStyles = {
        overflow: "scroll",
        container: (provided: any) => ({
            ...provided,
            width: 180,
        }),
        menu: (provided: any) => ({
            ...provided,
            // width: state.selectProps.width,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
        }),
        // eslint-disable-next-line no-unused-vars
        option: (provided: any, { isFocused }: any) => ({
            ...provided,
            // borderBottom: "1px dotted pink",
            color: isFocused ? "#F3F2F1" : null,
            backgroundColor: isFocused
                ? this.state.styles.siteVariables.colorScheme.brand.backgroundHover
                : this.state.styles.siteVariables.bodyBackground,
        }),
        control: (provided: any) => ({
            ...provided,
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
            backgroundColor: this.state.styles.siteVariables.bodyBackground,
        }),
        input: (provided: any) => ({
            ...provided,
            color: this.state.styles.siteVariables.colorScheme.brand.backgroundHover,
        }),
    };

    componentDidMount() {
        // Get Auth token from AD
        const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
            // eslint-disable-next-line no-unused-vars
            successCallback: (result: string) => {
                Backend.getCompanies(result)
                    .then((json) => {
                        const listOfCompanies: any = [];
                        json.forEach(
                            (companies: {
                                company_name: { toString: () => string };
                                id: { toString: () => string };
                            }) => {
                                const c = { label: "", value: "" };
                                c.label = companies.company_name.toString();
                                c.value = companies.id.toString();
                                listOfCompanies.push(c);
                            }
                        );
                        // console.log(listOfCompanies);
                        this.setState({
                            companies: listOfCompanies,
                            jwtoken: result,
                        });
                    })
                    .catch((err) => console.log(err));
            },
            failureCallback: function (error: string) {
                console.log("Failure: " + error);
            },
            resources: [],
        };
        microsoftTeams.authentication.getAuthToken(authTokenRequest);
    }

    createNewCompany = async (companyName: any): Promise<string> => {
        const body = {
            company_name: companyName,
            locked: false,
        };
        return Backend.createCompany(this.state.jwtoken, body)
            .then((json) => {
                console.log(json);
                console.log(companyName);
                const newCompanyObj = {
                    label: companyName,
                    value: json.id,
                };
                this.setState({
                    companies: [...this.state.companies, newCompanyObj],
                });
                return json.id;
            })
            .catch((err) => {
                console.log(err);
                return "";
            });
    };

    getCompanyID = async (companyName: string): Promise<string> => {
        return Backend.getCompanies(this.state.jwtoken)
            .then((json) => {
                const ret = json.find(function (obj: { company_name: string }) {
                    return obj.company_name === companyName;
                });
                if (ret) {
                    return ret.id;
                } else {
                    return "";
                }
            })
            .catch((err) => {
                console.log(err);
                return "";
            });
    };

    handleChange = async (newValue: any) => {
        if (newValue && newValue.value) {
            const company = { name: newValue.label, id: "" };
            if (
                this.state.companies.some((obj) => {
                    return obj.label === newValue.label;
                })
            ) {
                console.log("This company exits");
                company.id = await this.getCompanyID(newValue.label);
            } else {
                console.log("Create new company!");
                company.id = await this.createNewCompany(newValue.label);
            }
            if (company.id !== "") {
                // console.log("company id found: ");
                // console.log(company.id);
                this.props.SelectedCompany(company);
            } else {
                console.error("Company ID was empty");
            }
        } else {
            this.props.HandleDisabled(true);
        }
    };
    handleInputChange = () => {
        //This triggers when input field closes/opens/clear etc. Do here something if needed.
        // console.log("Input Changed: ");
        // console.log(inputValue);
        // console.log(`action: ${actionMeta.action}`);
    };
    render() {
        return (
            <CreatableSelect
                isClearable
                maxMenuHeight={120}
                styles={this.customStyles}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                options={this.state.companies}
                placeholder="Select company"
                noOptionsMessage={() => "Add new company by typing its name"}
            />
        );
    }
}
