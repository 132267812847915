import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import "../utils/css/App.css";
import {
    /* Form, FormButton, FormInput, */ Button,
    Input,
    mergeThemes,
    Provider,
    teamsDarkTheme,
    teamsTheme,
    Text,
} from "@fluentui/react-northstar";
import jwt_decode from "jwt-decode";
import * as Constants from "../utils/constants";
import * as Backend from "../actions/backendCalls";
import ProjectSettings from "./ProjectSettings";
import AlertExampleUrgent from "./Alert";
import CustomLoader from "./CustomLoader";
import { IProject, User } from "../types/types";

interface Istate {
    theme: string;
    context: any;
    userToken: any;
    firstName: string;
    lastName: string;
    decodedToken: any;
    jwtoken: string;
    isMobile: boolean;
    companiesData: any[];
    companiesList: any[];
    user: User;
    defaultProjectName: string;
    hasAlert: boolean;
    alertMsg: string;
    success: boolean;
    versionNumber: string | null;
    ready: boolean;
    errorMsg: string;
    error: boolean;
}

class SettingsTab extends React.Component {
    state: Istate;
    onSubmit: any;
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.state = {
            theme: "",
            context: {},
            userToken: {},
            firstName: "",
            lastName: "",
            jwtoken: "",
            decodedToken: {},
            isMobile: window.innerWidth >= Constants.MOBILEWIDTH ? false : true,
            companiesData: [""],
            companiesList: [],
            user: {
                id: "",
                app_settings: {
                    type: {},
                    visible_projects: [""],
                    properties: {
                        theme: "",
                    },
                    default_project: {
                        company_name: "",
                        company_id: "",
                        project_name: "",
                        project_id: "",
                        default_project_string: "",
                    },
                },
                full_name: "",
                email: "",
                oid: "",
                disabled: false,
                is_admin: false,
                is_project_manager: false,
                status_updated: 0,
            },
            defaultProjectName: "",
            hasAlert: false,
            alertMsg: "",
            success: false,
            versionNumber: null,
            ready: false,
            errorMsg: "",
            error: false,
        };
        this.onSubmit = this.handleSubmit.bind(this);
    }

    getUser = async () => {
        if (this.state.jwtoken !== "") {
            const user: User = await Backend.getUser(
                this.state.jwtoken
                // this.state.decodedToken.oid
            );
            this.setState({
                user: user,
                firstName: user.full_name.split(" ")[0],
                lastName: user.full_name.split(" ")[1],
            });
            this.setState({
                ready: true,
            });
        } else {
            throw "JW token missing";
        }
    };

    // eslint-disable-next-line no-unused-vars
    handleSubmit = () => {
        console.log("SUBMIT");
        // this.setState({
        //     firstName: e.target["firstName"].value,
        //     lastName: e.target["lastName"].value,
        //     email: e.target["email"].value,
        // });
    };

    updateMobileState = () => {
        this.setState({
            isMobile: window.innerWidth >= Constants.MOBILEWIDTH ? false : true,
        });
    };

    componentDidUpdate() {
        window.addEventListener("resize", this.updateMobileState);
        if (this.state.versionNumber === null) {
            this.setState({ versionNumber: process.env.REACT_APP_VERSION });
            console.log("Frontend version number: " + process.env.REACT_APP_VERSION);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateMobileState);
    }

    getCompanies = async () => {
        const companies = await Backend.getCompanies(this.state.jwtoken);
        const listOfCompanies: any = [];
        companies.forEach((company) => {
            listOfCompanies.push(company.company_name);
        });

        this.setState({
            companiesList: listOfCompanies,
            companiesData: companies,
        });
    };

    handleDisMiss = (dismiss: any) => {
        this.setState({
            hasAlert: dismiss,
            alertMsg: "",
            // success: false,
        });
    };

    //React lifecycle method that gets called once a component has finished mounting
    //Learn more: https://reactjs.org/docs/react-component.html#componentdidmount
    componentDidMount() {
        // Get the user context from Teams and set it in the state
        microsoftTeams.getContext((context: microsoftTeams.Context) => {
            this.setState({
                context: context,
            });
        });

        microsoftTeams.registerOnThemeChangeHandler((theme) => {
            if (theme !== this.state.theme) {
                this.setState({ theme });
            }
        });
        // Get Auth token from AD
        const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
            // eslint-disable-next-line no-unused-vars
            successCallback: async (result: string) => {
                try {
                    const decodedToken = jwt_decode(result);
                    this.setState({
                        jwtoken: result,
                        decodedToken: decodedToken,
                    });
                    await this.getUser();
                    await this.getCompanies();
                } catch (err: any) {
                    if (err.status === 409) {
                        this.setState({
                            error: true,
                            errorMsg:
                                "Frontend and backend are not in sync. Please wait a few minutes and try again. If that doesn't help please contact Toni.K or Levi",
                        });
                        return;
                    }
                    console.error(err);
                    this.setState({
                        error: true,
                        errorMsg: err,
                    });
                }
            },
            failureCallback: function (error: string) {
                console.log("Failure: " + error);
            },
            resources: [],
        };
        microsoftTeams.authentication.getAuthToken(authTokenRequest);
        microsoftTeams.appInitialization.notifySuccess();

        window.addEventListener("resize", this.updateMobileState);
    }
    render() {
        let newTheme;
        // Default theme
        let setTheme = {
            siteVariables: {},
            componentVariables: {
                TableRow: {
                    headerFontSize: "1rem",
                    bodyFontSize: "1rem",
                    cellPadding: "0.1rem 0.1rem",
                },
            },
            componentStyles: {},
        };

        let theme = this.state.theme; // theme (dark, light, highres)
        if (!theme) {
            theme = this.state.context["theme"];
        }
        if (theme === "default" || theme === "light") {
            newTheme = teamsTheme;
        } else if (theme === "dark" || theme === undefined) {
            newTheme = teamsDarkTheme;
        }

        // theme for mobile mode
        if (this.state.isMobile) {
            setTheme = {
                siteVariables: {},
                componentVariables: {
                    TableRow: {
                        headerFontSize: "0.5rem",
                        cellPadding: "0.1rem 0.1rem",
                        bodyFontSize: "0.7rem",
                    },
                },
                componentStyles: {},
            };
        }

        return (
            <Provider theme={mergeThemes(newTheme, setTheme)}>
                {!this.state.error ? (
                    <div>
                        {this.state.context["theme"] && this.state.ready ? (
                            <div
                                style={{
                                    paddingLeft: 50,
                                    paddingRight: 50,
                                    paddingBottom: 50,
                                    display: "inlineBlock",
                                }}
                            >
                                <h2>
                                    {`Configure project settings`}{" "}
                                    <Text
                                        color={"red"}
                                        weight="bold"
                                        size="largest"
                                        content={`${this.state.user.is_project_manager ? "Project Manager" : ""}${
                                            this.state.user.is_admin ? "Admin" : ""
                                        }`}
                                    />
                                </h2>
                                <ProjectSettings
                                    jwtoken={this.state.jwtoken}
                                    companiesList={this.state.companiesList}
                                    companiesData={this.state.companiesData}
                                    decodedToken={this.state.decodedToken}
                                    admin={this.state.user.is_admin}
                                    manager={this.state.user.is_project_manager}
                                />

                                <h2>
                                    {`Configure User settings`}{" "}
                                    <Text
                                        color={"red"}
                                        weight="bold"
                                        size="largest"
                                        content={`${this.state.user.is_project_manager ? "Project Manager" : ""}${
                                            this.state.user.is_admin ? "Admin" : ""
                                        }`}
                                    />
                                </h2>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "left",
                                        gap: "0.5rem",
                                    }}
                                >
                                    <Input
                                        variables={
                                            {
                                                // inputPadding: "0.5rem",
                                            }
                                        }
                                        input={{
                                            style: {
                                                textAlign: "left",
                                                // fontSize: "30px",
                                                // fontWeight: "bold",
                                                // minHeight: "50px",
                                                // maxWidth: "80%",
                                                minWidth: "300px",
                                            },
                                        }}
                                        autoComplete="off"
                                        type="text"
                                        placeholder={
                                            !this.state.user.app_settings.default_project ||
                                            this.state.user.app_settings.default_project.default_project_string === ""
                                                ? "Type default project name here"
                                                : `Current one is: "${this.state.user.app_settings.default_project.default_project_string}"`
                                        }
                                        id={`default-project-id`}
                                        // value={this.state.user.app_settings.default_project.default_project_string}
                                        onChange={(e) => {
                                            const target = e.target as HTMLTextAreaElement;
                                            console.log(target.value);
                                            this.setState({
                                                defaultProjectName: target.value,
                                            });
                                            // setProjectName(target.value);
                                        }}
                                        inline
                                    />
                                    <Button
                                        content="Update default project"
                                        disabled={this.state.defaultProjectName === ""}
                                        onClick={async () => {
                                            try {
                                                let _user: User = await Backend.getUser(this.state.jwtoken);
                                                const companyName = Constants.DEFAULT_COMPANY_NAME;
                                                const projectName = Constants.DEFAULT_PROJECT_NAME;
                                                let companyId: string;
                                                let projectId: string | undefined;
                                                //TODO: change how getCompanySettings returns data
                                                const projectSettings: IProject[] = await Backend.getProjectSettings(
                                                    this.state.jwtoken,
                                                    projectName
                                                );
                                                console.log(projectSettings);
                                                if (projectSettings.length > 0) {
                                                    companyId = projectSettings[0].company_id;
                                                    projectId = projectSettings[0].id;
                                                } else {
                                                    throw new Error("Project not found");
                                                }
                                                const appSettings = _user.app_settings;
                                                appSettings.default_project = {
                                                    company_name: companyName,
                                                    company_id: companyId,
                                                    project_name: projectName,
                                                    project_id: projectId!,
                                                    default_project_string: this.state.defaultProjectName,
                                                };

                                                const body = {
                                                    app_settings: appSettings,
                                                };

                                                console.log(body);

                                                _user = await Backend.updateUserAppSettings(
                                                    this.state.jwtoken,
                                                    body
                                                ).catch((err) => {
                                                    throw (
                                                        err +
                                                        ". This probably means that nothing was changed because the new default project name is the same as the old one."
                                                    );
                                                });

                                                console.log(_user);
                                                this.setState({
                                                    user: _user,
                                                    success: true,
                                                    alertMsg: `Default project name updated to "${_user.app_settings.default_project.default_project_string}"`,
                                                    hasAlert: true,
                                                });
                                            } catch (error) {
                                                console.error(error);
                                                this.setState({
                                                    hasAlert: true,
                                                    alertMsg: error,
                                                    success: false,
                                                });
                                            }
                                        }}
                                    />
                                    {this.state.hasAlert ? (
                                        <div
                                            style={{
                                                maxWidth: "300px",
                                            }}
                                        >
                                            <AlertExampleUrgent
                                                msg={this.state.alertMsg}
                                                handleDisMiss={this.handleDisMiss}
                                                success={this.state.success}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    style={{
                                        position: "absolute",
                                        left: 5,
                                        bottom: 5,
                                        color: "gray",
                                    }}
                                >
                                    Version: {this.state.versionNumber}
                                </div>
                            </div>
                        ) : (
                            <CustomLoader msg="Fetching data..." />
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            minHeight: "100vh",
                            minWidth: "100vw",
                        }}
                    >
                        <Text
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                            weight="bold"
                            size="large"
                            content={this.state.errorMsg}
                        />
                    </div>
                )}
            </Provider>
        );
    }
}
export default SettingsTab;
