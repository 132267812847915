import React from "react";
import "../utils/css/App.css";
import * as microsoftTeams from "@microsoft/teams-js";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import SettingsTab from "./SettingsTab";
import ReportsTab from "./ReportsTab";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
function App() {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.app.initialize();

    // Display the app home page hosted in Teams
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/" element={<Tab />} />
                <Route path="/settings" element={<SettingsTab />} />
                <Route path="/reports" element={<ReportsTab />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
