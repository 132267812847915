import * as React from "react";
import {
    Text,
    Table,
    Input,
    Button,
    TableCell,
    Datepicker,
    Tooltip,
    gridNestedBehavior,
    Flex,
    /*     Accordion, */
} from "@fluentui/react-northstar";
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon } from "@fluentui/react-icons-northstar";
import DescriptionDialog from "./AddDescriptionDialog";
import { getNextWeek, getPrevWeek, getThisWeek } from "../utils/time";
import * as Backend from "../actions/backendCalls";
import * as Constants from "../utils/constants";
import * as Generators from "../actions/generators";
import * as _ from "lodash";
const offcodeLogo = require("../images/favicon.ico");
import "../utils/css/App.css";
import { useEffect, useState } from "react";
import { usePrevious } from "../utils/usePrevious";
import CustomLoader from "./CustomLoader";
import { moveOnTop, sortOn } from "../utils/helpers";
import { IProject, IProjectResults, IWeekData, IUser, ITimeEntry } from "../types/types";

interface IWeekTableInterface {
    token: string;
    user: IUser;
}

export interface IDropdownElement {
    label: string; //company name
    value: string; //project ID
    project_id: string;
    image: any;
    key: string;
    private: boolean;
}

// Weektable component
const WeekTable = (props: IWeekTableInterface) => {
    const [projectsArr, setProjectsArr] = useState<string[]>([""]);
    const [dropdownArr, setDropdownArr] = useState<IDropdownElement[] | null>(null);
    const [visibleProjects, setVisibleProjects] = useState<string[] | null>(null);
    const [projectsData, setProjectsData] = useState<IProjectResults[] | null>(null);
    const [field, setField] = useState<IWeekData["dates"]>({});
    const [dayTotal, setDayTotal] = useState<{ [x: string]: number }>({});
    const [jwtoken, setJwtoken] = useState<string>(props.token);
    const [selectedDays, setSelectedDays] = useState<any[]>([]);
    const [dbHours, setDbHours] = useState<ITimeEntry[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [weekTotal, setWeekTotal] = useState<IWeekData["weekTotal"]>({});
    const [prev, setPrev] = useState<string | number | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [userData, setUserData] = useState<IUser>();
    const [weektotalReady, setWeekTotalReady] = useState<boolean>(false);
    const [hoursReady, setHoursReady] = useState<boolean>(false);
    const [canRender, setCanRender] = useState<boolean>(false);
    const [overflow, setOverflow] = useState<"auto" | "visible">("visible");
    const prevProps = usePrevious(props);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    // Props changes
    useEffect(() => {
        if (prevProps !== props) {
            setJwtoken(props.token);
        }
    }, [props]);

    // Component mounts
    useEffect(() => {
        const date = new Date();
        const thisWeek = getThisWeek(date);
        if (selectedDays !== thisWeek) setSelectedDays(thisWeek);
        update(true, thisWeek);
    }, []);

    useEffect(() => {
        // console.log(`Windows width: ${window.innerWidth}`);
        // console.log(`Overflow: ${window.innerWidth >= Constants.MOBILEWIDTH ? "visible" : "auto"}`);
        setOverflow(window.innerWidth >= Constants.MOBILEWIDTH ? "visible" : "auto");
    }, [window.innerWidth]);

    useEffect(() => {
        if (visibleProjects !== null) {
            fetchProjects(); //fetch project
        }
    }, [visibleProjects]);

    useEffect(() => {
        if (hoursReady && weektotalReady) {
            setCanRender(true);
        }
    }, [hoursReady, weektotalReady]);

    // Get time entries(selected week)
    const fetchTimeEntries = async (week: any[]) => {
        try {
            if (week === undefined || week.length === 0) return;
            const date = {
                from: week[1].valueOf(),
                to: week[8].valueOf(),
            };
            const weekData: IWeekData = await Backend.getTimeEntriesBetween(jwtoken, date);
            // fetchHours(week); //Todo: should this be await?
            setWeekTotal(weekData.weekTotal);
            setWeekTotalReady(true);
            setDbHours(weekData.timeEntries);
            setField(weekData.dates);
            setDayTotal(weekData.sum);
            setHoursReady(true);
        } catch (error) {
            console.error(error);
        }
    };

    //TODO: This is horrible call. This takse long time and should not be like that. Fix me PLS
    const fetchProjects = async () => {
        try {
            const listOfProjects: any = [];
            const projects: IProjectResults[] = await Backend.fetchProjects(jwtoken);
            const newArr: IDropdownElement[] = [];
            if (projects.length > 0) {
                for (const project of projects) {
                    listOfProjects.push(`${project.company_name}-${project.project_name}_${project.id}`);
                    if (visibleProjects !== null) {
                        // Check that list does not include items that are already selected to canBeSeen array
                        if (!visibleProjects.some((val) => val === project.id)) {
                            newArr.push({
                                label: project.company_name,
                                value: project.project_name,
                                project_id: project.id!,
                                image: offcodeLogo,
                                key: project.company_name + project,
                                private: project.private,
                            });
                        }
                    }
                }
            }
            setDropdownArr(newArr);
            setProjectsData(projects);
            setProjectsArr(listOfProjects);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUser = async () => {
        try {
            let user: IUser;

            if (props.user === null) {
                user = await Backend.getUser(jwtoken);
            } else {
                user = props.user;
            }

            setIsAdmin(user.is_admin);
            setUserData(user);
            setVisibleProjects(user.app_settings.visible_projects);
        } catch (error) {
            console.error(error);
        }
    };

    // Update project list & time entries
    const update = async (setLoading: boolean | ((prevState: boolean) => boolean), week?: any[] | undefined) => {
        try {
            const use = week ? week : selectedDays;

            if (jwtoken !== "") {
                setIsLoading(setLoading);
                await fetchUser(); //fetch user
                await fetchTimeEntries(use); //fetch time entries
            } else {
                throw "jwtoken missing";
            }
        } catch (error) {
            console.error(error);
        }
    };

    // submit new time entry
    const submitTimeEntry = async (data: ITimeEntry) => {
        try {
            await Backend.submitTimeEntry(jwtoken, data);
            await fetchTimeEntries(selectedDays);
        } catch (error) {
            console.error(error);
        }
    };

    //Update existing time entry
    const updateTimeEntry = async (data: ITimeEntry, id: any) => {
        try {
            await Backend.updateTimeEntry(jwtoken, data, id);
            await fetchTimeEntries(selectedDays);
        } catch (error) {
            console.error(error);
        }
    };

    const deleteTimeEntry = async (projectID: any, from: number) => {
        await Backend.deleteTimeEntry(jwtoken, projectID, from);
        await fetchTimeEntries(selectedDays);
    };

    const handleDescriptionReady = async (state: any) => {
        if (state) {
            await fetchTimeEntries(selectedDays);
        }
    };

    //Handle hide event for the project
    const handleHide = (projectId: string, hasHours: any) => async () => {
        //Check that project has no hours or it is not the default project
        try {
            if (!hasHours && projectId !== Constants.DEFAULT_PROJECT_ID) {
                if (visibleProjects !== null) {
                    const currArr = [...visibleProjects];

                    //HACK: Just in case we have a some object in array
                    for (let i = 0; i < currArr.length; i++) {
                        //if currArr[i] is object remove it from array
                        if (typeof currArr[i] === "object") {
                            console.log(`currArr[${i}] is object, remove it from the array`);
                            currArr.splice(i, 1);
                        }
                    }

                    const index = currArr.indexOf(projectId);
                    if (index !== -1) {
                        currArr.splice(index, 1);
                    }
                    const appSettings = userData?.app_settings;

                    if (appSettings) {
                        appSettings.visible_projects = currArr;
                    }

                    const body = {
                        app_settings: appSettings,
                    };

                    const user: IUser = await Backend.updateUserAppSettings(jwtoken, body);
                    const _visibleProjects = user.app_settings.visible_projects;
                    // console.log("Thise are the visible projects after hiding:");
                    // console.log(_visibleProjects);
                    setVisibleProjects(_visibleProjects);
                }
            } else {
                console.log("Cannot hide project");
            }
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Check if projects object has project with given id and from date is same as given(current cell date)
     * @param projectID project id
     * @param date current cell date
     * @returns boolean true if project is found and false if not
     */
    const getProjectObject = (projectID: any, date: { valueOf: () => any }) => {
        let ret: boolean = false;
        ret = dbHours.some((project) => {
            return project.project_id === projectID && project.from === date.valueOf();
        });
        return ret;
    };

    const getDescription = (projectID: string, date: { valueOf: any }) => {
        let description = null;
        dbHours.some((entry) => {
            if (entry.project_id === projectID && entry.from === date.valueOf() && entry.description !== "") {
                description = entry.description;
                return null;
            }
            return null;
        });

        return description;
    };

    /**
     * Get project index from dbHours array if dbhours has project with given id and from date is same as given(current cell date)
     * @param projectID project id
     * @param date curren cell date
     * @returns project index from dbHours array or -1 if not found
     */
    const getProjectIndex = (projectID: any, date: { valueOf: any }) => {
        let ret: any = -1;
        ret = dbHours.findIndex((obj: ITimeEntry) => {
            return obj.project_id === projectID && obj.from === date.valueOf();
        });
        return ret;
    };

    /**
     *
     * @param projectID project id
     * @param date current cell date
     * @param onlyID if true returns only id of time entry
     * @returns either fulls string (projectID + date) or timeEntry database id or undefined if project is not found
     */
    const handleID = (projectID: any, date: { valueOf: () => any }, onlyID: boolean) => {
        let id: string | undefined = undefined;

        if (getProjectObject(projectID, date)) {
            const idx = getProjectIndex(projectID, date);
            if (onlyID) {
                id = dbHours[idx].id; //timeEntry database id
            } else {
                id = `${projectID}-${date.valueOf()}`; //Full string (projectid + date)
            }
        }
        return id;
    };

    const handleOnChange = (data: { value: any }) => {
        let current = data.value.replace(",", ".");
        if (current > Constants.MAXHOURS) current = Constants.MAXHOURS;
        if (current < Constants.MINHOURS) current = Constants.MINHOURS;
        return current;
    };

    const handleOnBlur = async (
        target: EventTarget & HTMLInputElement,
        prev: string | number | null,
        projectID: any,
        date: { valueOf: () => any },
        project: IProjectResults
    ) => {
        try {
            let current: string | number = target.value as unknown as number;
            if (isNaN(current)) current = "0";
            const from: number = Number(date.valueOf());
            const to: number = from + 3600 * 1000 * parseFloat(current as string);
            const today = new Date();

            if (current !== prev) {
                if (current == 0) {
                    //delete time entry from database
                    console.log("Delete time entry");
                    await deleteTimeEntry(projectID, from);
                } else {
                    // We use id field to tell that we have already timeEntry in database for this field
                    const idx = getProjectIndex(projectID, date);
                    if (idx === -1) {
                        // create time entry
                        const submitObj: ITimeEntry = {
                            project_id: projectID,
                            created_by: "",
                            created: today.valueOf(),
                            modified: 0,
                            description: "",
                            from: from,
                            to: to,
                            approved: false,
                        };
                        await submitTimeEntry(submitObj);
                    } else {
                        // update time entry
                        const submitObj: ITimeEntry = dbHours[idx];
                        submitObj.from = from;
                        submitObj.to = to;
                        submitObj.modified = today.valueOf();
                        await updateTimeEntry(submitObj, submitObj.id);
                    }
                }

                if (project.budget) {
                    console.log("Project has budget: ", project.budget);
                    const body: IProjectResults = _.cloneDeep(project);
                    // delete body.company;

                    let _prev = prev;
                    if (typeof _prev === "string") _prev = parseFloat(_prev);
                    if (_prev === null || isNaN(_prev)) _prev = 0;

                    if (typeof current === "string") current = parseFloat(current);
                    if (_prev === null || isNaN(current)) current = 0;

                    body.budget.used = project.budget.used ? project.budget.used - (_prev - current) : current;

                    const ret: IProject = await Backend.updateProjectSettings(jwtoken, body, body.id);
                    const newObject = _.cloneDeep(projectsData); //make deep copy of original object

                    const index = newObject?.findIndex((x) => {
                        //find right object based on id
                        if (x.id === ret.id) return true;
                        else return false;
                    });

                    if (newObject !== null && index !== -1 && index) {
                        console.log(newObject[index]);
                        newObject[index].budget = ret.budget; //update with new value
                    }

                    setProjectsData(newObject);
                }
            }
        } catch (error) {
            console.error(error);
            // Show error message to user
            setError(true);
            setErrorMsg(`Error while updating time entry: ${error}`); //TODO: This should be shown to user
        }
    };

    const handleProjectOnChange = async (project: IDropdownElement) => {
        if (!dropdownArr || !visibleProjects || !userData?.app_settings) return;

        const newArr = dropdownArr.filter((item) => item !== project);
        const newItem = project.project_id;

        // Check that newItem is not already in array
        if (visibleProjects.includes(newItem)) return;

        const updatedVisibleProjects = [...visibleProjects, newItem];
        const appSettings = {
            ...userData.app_settings,
            visible_projects: updatedVisibleProjects,
        };

        const body = { app_settings: appSettings };

        try {
            const user = await Backend.updateUserAppSettings(jwtoken, body);
            setVisibleProjects(user.app_settings.visible_projects);
            setDropdownArr(newArr);
        } catch (error) {
            console.error("Failed to update user app settings", error);
        }
    };

    //This is called when Dialog component project list is updated.
    // This means that we should fetch new project list from database
    const handleNewProject = () => {
        update(false);
    };

    const handleWeekChange = (week?: any[]) => {
        setWeekTotalReady(false);
        setHoursReady(false);
        setCanRender(false);

        if (week) {
            setSelectedDays(week);
            fetchTimeEntries(week); //fetch time entries
        }
    };

    // Get previous week days
    const handlePrev = () => {
        handleWeekChange(getPrevWeek(selectedDays[0]));
    };

    // Get next week days
    const handleNext = () => {
        handleWeekChange(getNextWeek(selectedDays[0]));
    };

    // Get current week days
    const handleGoToday = () => {
        handleWeekChange(getThisWeek(new Date()));
    };

    // Generate Data Cell
    const generateDateCell = (
        i: number,
        locked: boolean,
        placeholder: string | undefined,
        projectID: string,
        hasDescription: string | null,
        fieldName: string,
        project: IProjectResults
    ) => {
        const id = handleID(projectID, selectedDays[i + 1], false); //Full string (projectid + date) or undefined if project is not found
        const timeEntryID = handleID(projectID, selectedDays[i + 1], true); //timeEntry database id for Description dialog
        return {
            key: `${projectID}-${i}`,
            content: (
                <div>
                    <Input
                        variables={{
                            inputPadding: "0.1rem",
                        }}
                        input={{
                            style: {
                                textAlign: "center",
                                fontSize: "normal",
                                fontWeight: "semibold",
                                minHeight: "40px",
                            },
                        }}
                        autoComplete="off"
                        disabled={locked && projectID !== Constants.DEFAULT_PROJECT_ID}
                        type="text"
                        fluid
                        placeholder={placeholder}
                        id={id}
                        icon={
                            id !== undefined ? (
                                <Tooltip
                                    trigger={
                                        <DescriptionDialog
                                            id={timeEntryID}
                                            token={jwtoken}
                                            hasDescription={hasDescription}
                                            submitReady={(state: any) => handleDescriptionReady(state)}
                                        />
                                    }
                                    content={"Add description"}
                                />
                            ) : (
                                <div></div>
                            )
                        }
                        value={field[fieldName] ? field[fieldName] : ""}
                        onBlur={(e) => {
                            handleOnBlur(e.target, prev, projectID, selectedDays[i + 1], project);
                        }}
                        onFocus={(e) => {
                            setPrev(e.target.value);
                        }}
                        onChange={(e, data) => {
                            if (data) {
                                const newValue = handleOnChange(data);
                                const updatedField = { ...field }; // get old field object
                                updatedField[fieldName] = newValue;
                                setField(updatedField);
                            }
                        }}
                    />
                </div>
            ),
            truncateContent: true,
            styles: {
                minWidth: "100px",
                maxWidth: "100px",
                marginRight: 0,
                // border: "2px solid blue",
            },
        };
    };

    // const handleDuration = async (projectID, company, project, duration) => {
    //     const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    //     const idToLookFor = `${company}_${project}_${today.valueOf()}`;
    //     const elem = document.getElementById(idToLookFor);
    //     const oid: any = decodedTok.oid;
    //     const from: number = Number(today.valueOf());
    //     const split = duration.split(":");
    //     const durationInSeconds = Number(split[0] * 60 * 60 + split[1] * 60 + split[2]);
    //     const to: number = from + durationInSeconds * 1000;
    //     const today_exact = new Date();

    //     type TimeEntry = {
    //         project_id: string;
    //         created_by: string;
    //         created: number;
    //         modified: number;
    //         description: string | null;
    //         from: number;
    //         to: number;
    //         approved: boolean;
    //     };

    //     const submitObj: TimeEntry = {
    //         project_id: projectID,
    //         created_by: oid,
    //         created: today_exact.valueOf(),
    //         modified: 0,
    //         description: getDescription(projectID, today),
    //         from: from,
    //         to: to,
    //         approved: false,
    //     };

    //     if (elem !== null) {
    //         const idx = getProjectIndex(projectID, today);
    //         const timeEntryID = dbHours[idx].id;
    //         submitObj["modied"] = today_exact.valueOf();
    //         await updateTimeEntry(submitObj, timeEntryID);
    //     } else {
    //         console.error("There is no such element");
    //         console.log("create timeEntry");
    //         await submitTimeEntry(submitObj);
    //     }
    // };

    // Generate new project row
    const generateProjectRow = (index: number) => {
        const row: any[] = [];
        let project: IProjectResults | null = null;
        if (projectsData) {
            project = projectsData[index];
        }
        if (project) {
            const companyName = project.company_name;
            // const companyID = project.company!.id;
            const projectID = project.id!;
            const disHasHours = weekTotal[projectID] ? true : false;
            const projectName = project.project_name;
            //Generate row if it has hours marked or if it's included in visibleProjects or if it's the default project
            if (
                disHasHours ||
                visibleProjects?.includes(projectID) /* || projectID === Constants.DEFAULT_PROJECT_ID */
            ) {
                const isPrivate = project.private;
                const activeFrom = project.active.from;
                const activeTo = project.active.to;
                const isLocked = project.entry_locked;

                const privColor = isPrivate ? "green" : projectID === Constants.DEFAULT_PROJECT_ID ? "red" : "orange";

                const privText = isPrivate
                    ? "Private Project"
                    : projectID === Constants.DEFAULT_PROJECT_ID
                    ? "Default Project (Allways visible)"
                    : "Public Project";

                let hasDescription: string | null = null;

                // First cell of the row (company name & project name)
                row.push(
                    Generators.generateFirstCell(
                        companyName,
                        projectName,
                        projectID,
                        isLocked,
                        privColor,
                        privText,
                        disHasHours
                    )
                );

                // Monday-Sunday cells
                for (let i = 0; i < 7; i++) {
                    const fieldName = projectID + "_" + selectedDays[i + 1];

                    let locked = isLocked;
                    let placeholder = "";
                    if (!locked) {
                        if (activeFrom) {
                            if (!(activeFrom <= selectedDays[i + 1].valueOf())) {
                                locked = true;
                            }
                        }
                        if (activeTo) {
                            if (!(selectedDays[i + 1].valueOf() <= activeTo)) {
                                locked = true;
                            }
                        }
                    }
                    if (locked) {
                        if (projectID === Constants.DEFAULT_PROJECT_ID) placeholder = "";
                        else placeholder = "locked";
                    }

                    hasDescription = getDescription(projectID, selectedDays[i + 1]);

                    /** Date cells */
                    row.push(generateDateCell(i, locked, placeholder, projectID, hasDescription, fieldName, project));
                }

                /** Cell for total hours */
                row.push(Generators.generateWeekTotalCell(companyName, projectName, weekTotal, projectID));

                /** Cell for hide icon */
                row.push(Generators.generateHideCell(projectName, projectID, disHasHours, handleHide));
                // This is has the play button and timer for the running timer. Not implemented yet
                // row.push(Generators.generateTimeEntryInput(projectID, companyName, projectName, handleDuration));

                // row.push(Generators.generateBudgetCell(companyName, projectName, projectID, project.budget));
            }
        }
        return row;
    };

    // This collects all project rows and generate row object for table
    const generateRows = () => {
        let rows: any[] = [];
        const projectsToBeSeenArr: any[] = [];

        // Generate rows based on array of projects found
        rows = projectsArr.map((project, index) => ({
            key: String(project),
            items: generateProjectRow(index),
            styles: { height: "auto" },
        }));

        sortOn(rows, "key"); //Sort rows based on project name

        moveOnTop(rows, Constants.DEFAULT_PROJECT_ID); //Move default project on top

        // Check if project has hours set. If so add it to rows, otherwise skip.
        // Added also check if user selected project to be seen even without hours.
        rows.forEach((row) => {
            if (row.items.length !== 0) {
                // Check if row has any items
                const projectID = row.key.split("_")[1]; // Get projectID from row key
                // This handles the visible project selection.
                // If project has hours or is selected to be seen (or is default project), add it to rows
                if (
                    row.items[0].hashours ||
                    visibleProjects!.includes(projectID) ||
                    projectID === Constants.DEFAULT_PROJECT_ID
                ) {
                    // console.log(row);
                    projectsToBeSeenArr.push(row);
                }
            }
        });

        // Select project row at the bottom of the weektable
        projectsToBeSeenArr.push({
            key: "selectProjectRow",
            items: Generators.generateSelectProject(
                dropdownArr,
                handleProjectOnChange,
                isAdmin,
                // decodedTok,
                handleNewProject,
                jwtoken
            ),
        });

        // Day total cell row at the bottom of the weektable
        projectsToBeSeenArr.push({
            key: "dayTotalCell",
            items: Generators.generateDayTotalCell(selectedDays, dayTotal, weekTotal),
        });

        return projectsToBeSeenArr;
    };

    // const importFileAccordion = () => {
    //     const accordionItems = [
    //         {
    //             title: "Import File",
    //             content: <ImportFile key="importfile" token={jwtoken} userData={userData} />,
    //         },
    //     ];
    //     return accordionItems;
    // };

    // Return WeekTable Object
    return (
        <div>
            {isLoading || !canRender ? (
                <CustomLoader msg="Fetching data" />
            ) : (
                <div>
                    {error ? (
                        <p
                            style={{
                                textAlign: "center",
                                border: "2px solid red",
                                borderRadius: "5px",
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "20px",
                                padding: "10px",
                                // backgroundColor: "white",
                            }}
                        >
                            <Text color="red" weight="bold" size="large" content={errorMsg} />
                            <Button
                                //add some space between text and button
                                style={{
                                    marginLeft: "10px",
                                    height: "30px",
                                    width: "40px",
                                }}
                                iconOnly
                                icon={<CloseIcon />}
                                onClick={() => setError(false)}
                            />
                        </p>
                    ) : null}
                    <Table
                        key="table"
                        // variables={handleVariables}
                        accessibility={gridNestedBehavior}
                        styles={({ theme: { siteVariables } }) => ({
                            color: siteVariables.colorScheme.brand.foreground,
                        })}
                    >
                        <TableCell
                            styles={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            <p></p>
                        </TableCell>
                        <TableCell
                            styles={{
                                marginTop: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            <Flex gap="gap.small">
                                <Button
                                    iconOnly
                                    size="medium"
                                    key="previousButton"
                                    icon={<ArrowLeftIcon />}
                                    id="1"
                                    onClick={handlePrev}
                                />
                                <Datepicker
                                    buttonOnly
                                    today={new Date()}
                                    key="datePicker"
                                    id="2"
                                    onDateChange={(e, data) => {
                                        if (data) handleWeekChange(getThisWeek(data.value));
                                    }}
                                />
                                <Button
                                    iconOnly
                                    size="medium"
                                    key="nextButton"
                                    icon={<ArrowRightIcon />}
                                    id="3"
                                    onClick={handleNext}
                                />
                                <Button
                                    // iconOnly
                                    size="medium"
                                    key="goToday"
                                    content="TODAY"
                                    // icon={<ArrowRightIcon />}
                                    id="4"
                                    onClick={handleGoToday}
                                />
                            </Flex>
                        </TableCell>
                        <p></p>
                    </Table>
                    <Table
                        style={{
                            maxWidth: "90vw",
                            maxHeight: "200vh",
                            overflowX: overflow,
                            overflowY: "visible",
                        }}
                        key="weekTable"
                        // accessibility={gridNestedBehavior}
                        header={Generators.generateHeaders(selectedDays)}
                        rows={generateRows()}
                    />
                    {/* <p></p> */}
                    {/* <Accordion defaultActiveIndex={[1]} panels={importFileAccordion()} /> */}
                </div>
            )}
        </div>
    );
};
export default WeekTable;
